/* eslint-disable react/no-unused-prop-types,react/forbid-prop-types,react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

const LoginRedirect = () => (
  <div>
    Please wait...
  </div>
);

// Runtime type checking for React props
LoginRedirect.propTypes = {
  authenticated: PropTypes.bool,
  history: PropTypes.object,
};

export default LoginRedirect;
