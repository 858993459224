import React from 'react';
import { Admin, Resource } from 'react-admin';

import Amplify, { Hub } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';

import MerchantApiDataProvider from './DataProvider';
import AuthProvider from './AuthProvider';
import Login from './layout/LoginPage';

import Merchants from './merchants';
import OperatingCompanies from './operating_companies';
import ApiKeys from './api_keys';
import Webhooks from './webhooks';
import Versions from './versions';

// import Dashboard from './Dashboard';

import customRoutes from './customRoutes';

import authListener from './authentication/authListener';

import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_TOKEN,
  datacenter: 'us',
  forwardErrorsToLogs: true,
  sampleRate: 100
});

Hub.listen('auth', authListener);

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
      ],
      redirectSignIn: process.env.REACT_APP_COGNITO_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_COGNITO_SIGN_OUT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
  },
});

const App = () => (
  <Admin
    dataProvider={MerchantApiDataProvider}
    authProvider={AuthProvider}
    loginPage={Login}
    // dashboard={Dashboard}
    customRoutes={customRoutes}
  >
    <Resource name="merchants" {...Merchants} />
    <Resource name="operating_companies" {...OperatingCompanies} />
    <Resource name="api_keys" {...ApiKeys} />
    <Resource name="webhook_endpoints" options={{label: 'Webhooks'}} {...Webhooks}/>
    <Resource name="versions" {...Versions}/>
  </Admin>
);

export default withOAuth(App);
