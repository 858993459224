import { stringify } from 'qs';
import merge from 'deepmerge';
import axios from 'axios';
import jsonapiClient from 'ra-jsonapi-client';

const settings = {
  serializerOpts: {
    merchants: {
      keyForAttribute: 'snake_case',
    },
    operating_companies: {
      keyForAttribute: 'snake_case',
    },
    api_keys: {
      keyForAttribute: 'snake_case',
    },
    webhook_endpoints: {
      keyForAttribute: 'snake_case',
    },
    versions: {
      keyForAttribute: 'snake_case',
    },
  },
  deserializerOpts: {
    merchants: {
      keyForAttribute: 'snake_case',
    },
    operating_companies: {
      keyForAttribute: 'snake_case',
    },
    api_keys: {
      keyForAttribute: 'snake_case',
    },
    webhook_endpoints: {
      keyForAttribute: 'snake_case',
    },
    versions: {
      keyForAttribute: 'snake_case',
    },
  },
};

const dp = jsonapiClient(process.env.REACT_APP_API_PATH, settings);

const dataProvider = {
  ...dp,
  getManyReference: (resource, params) => {

    if (resource === 'versions') {
      let url = '';

      const defaultSettings = {
        total: 'total',
        headers: {
          Accept: 'application/vnd.api+json; charset=utf-8',
          'Content-Type': 'application/vnd.api+json; charset=utf-8',
        },
        updateMethod: 'PATCH',
        arrayFormat: 'brackets',
      };
      const userSettings = merge(defaultSettings, settings);

      const options = {
        headers: userSettings.headers,
      };
      const { page, perPage } = params.pagination;

      // Create query with pagination params.
      const query = {
        'page[number]': page,
        'page[size]': perPage,
      };

      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        query[`filter[${key}]`] = params.filter[key];
      });

      // Add the reference id to the filter params.
      query[`filter[${params.target}]`] = params.id;

      // Add sort parameter
      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-';
        query.sort = `${prefix}${params.sort.field}`;
      }

      let target_path = params.target.replaceAll('__','/');
      target_path = target_path.replaceAll('rxid',params.id);
      url = `${process.env.REACT_APP_API_PATH}/${target_path}?${stringify(query)}`;

      return axios({ url, ...options })
          .then((response) => {
            let total;

            if (response.data.meta && userSettings.total) {
              total = response.data.meta[userSettings.total];
            }

            total = total || response.data.data.length;


            return {
              data: response.data.data.map(value => Object.assign(
                  { id: value.id },
                  value.attributes,
              )),
              total,
            };

          });
    }
    else {
      return dp('GET_MANY_REFERENCE', resource, params);
    }
  },
  getList: (resource, params) => { return dp('GET_LIST', resource, params); },
  getOne: (resource, params) => { return dp('GET_ONE', resource, params); },
  getMany: (resource, params) => { return dp('GET_MANY', resource, params); },
  create: (resource, params) => { return dp('CREATE', resource, params); },
  update: (resource, params) => { return dp('UPDATE', resource, params); },
  updateMany: (resource, params) => { return dp('UPDATE_MANY', resource, params); },
  delete: (resource, params) => { return dp('DELETE', resource, params); },
  deleteMany: (resource, params) => { return dp('DELETE_MANY', resource, params); },
};

export default dataProvider;
