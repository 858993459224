/* eslint-disable react/prop-types */
import React from 'react';

export const VersionTitle = ({ record }) => (
  <span>
    Version
    {record ? ` > ${record.event} - ${record.id}` : ''}
  </span>
);

export default VersionTitle;
