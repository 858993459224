// import {ApiKeyList} from "./ApiKeyList";
import { ApiKeyShow } from './ApiKeyShow';
import { ApiKeyEdit } from './ApiKeyEdit';
import { ApiKeyCreate } from './ApiKeyCreate';

export default {
  show: ApiKeyShow,
  edit: ApiKeyEdit,
  create: ApiKeyCreate,
};
