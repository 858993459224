import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  BooleanInput,
} from 'react-admin';

// import Typography from '@material-ui/core/Typography';
//
// const Aside = () => (
//   <div style={{ width: 200, margin: '1em' }}>
//     <Typography variant="h6">Post details</Typography>
//     <Typography variant="body2">
//       Posts will only be published one an editor approves them
//     </Typography>
//   </div>
// );

const MerchantFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Merchant Name" source="name_i_cont" alwaysOn />
    <TextInput label="OpCo Reference ID" source="operating_company_reference_id_i_cont" alwaysOn />
    <BooleanInput label="PCI Compliance Enabled" source="pci_compliance_enabled_eq" />
    <ReferenceInput label="Operating Company" source="operating_company_reference_token_eq" reference="operating_companies" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const MerchantList = (props) => (
  <List filters={<MerchantFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <ReferenceField label="Operating Company" source="operating_company.id" reference="operating_companies" sortBy="operating_company_name">
        <TextField source="name" />
      </ReferenceField>
      {/* <TextField source="id" label="Merchant ID"/> */}
      <TextField source="name" />
      <TextField source="operating_company_reference_id" label="OpCo Reference ID" />
      <BooleanField source="pci_compliance.enabled" label="PCI Compliance Enabled" sortBy="pci_compliance_enabled" />
      <TextField source="pci_compliance.boarding_status" label="PCI Boarding Status" sortBy="pci_compliance_boarding_status" />
      <TextField source="pci_compliance.overall_pci_compliance_status" label="PCI Overall Status" sortBy="pci_compliance_overall_pci_compliance_status" />

    </Datagrid>
  </List>
);

export default MerchantList;
