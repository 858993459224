import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { validateOpCpName } from './validations';

export const OperatingCompanyCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="operating_companies">
      <TextInput source="name" validate={validateOpCpName} />
      <TextInput source="control_scan_partner_guid" />
    </SimpleForm>
  </Create>
);

export default OperatingCompanyCreate;
