// eslint-disable-next-line max-len
/* eslint-disable import/no-extraneous-dependencies,react/require-default-props,react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '@material-ui/icons/Lock';

import { Notification } from 'react-admin';
import { useTranslate } from 'ra-core';

const LoginForm = ({ submitHandler, classes, loading }) => {
  const translate = useTranslate();
  return (
    <Form
      onSubmit={submitHandler}
      // validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.hint}>
                You need to sign in
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
                {/* Sign in with AWS */}
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  submitHandler: PropTypes.func,
  classes: PropTypes.object,
  loading: PropTypes.bool,
};

export default LoginForm;
