// eslint-disable-next-line max-len
/* eslint-disable import/no-extraneous-dependencies,react/no-unused-prop-types,react/require-default-props,react/forbid-prop-types */
import React, {useState} from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MonospacedTextField from "./MonospacedTextField";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import get from 'lodash/get';



export const ToggleHiddenField = ({source, record = {}}) => {
  const [show, setShow] = useState(false);
  const trueFieldValue = get(record, source);
  let textFieldValue = null;
  let showHideIcon = null;

  if (show) {
    showHideIcon = <VisibilityIcon style={{display: "inline", float: 'right'}} onClick={(e) => {
      e.stopPropagation();
      setShow(!show)
    }}/>;
    textFieldValue = trueFieldValue;
  } else {
    showHideIcon = <VisibilityOffIcon style={{display: "inline", float: 'right'}} onClick={(e) => {
      e.stopPropagation();
      setShow(!show)
    }}/>;
    textFieldValue = '************************';
  }
  return (
    <Box display="flex">
      <MonospacedTextField text={textFieldValue}/>
      {showHideIcon}
    </Box>
  );
};

ToggleHiddenField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/default-props-match-prop-types
ToggleHiddenField.defaultProps = {addLabel: true};

export default ToggleHiddenField;
