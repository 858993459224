/* eslint-disable camelcase */
import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';
import {parse} from 'query-string';
import WebhookEventSelectInput from "../fields/WebhookEventSelectInput";


export const WebhookCreate = (props) => {
  const {operating_company_id: operating_company_string} = parse(props.location.search);

  const operating_company_id = operating_company_string;
  const redirect = operating_company_id ? `/operating_companies/${operating_company_id}/show/webhooks` : false;

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="Operating Company"
          source="operating_company.id"
          reference="operating_companies"
          disabled={!!operating_company_id}
          validate={required()}
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <TextInput label="Target URL" source="target_url" type="url" validate={required()}/>
        <WebhookEventSelectInput source='events'/>
        <TextInput label="Token" source="token"/>
      </SimpleForm>
    </Create>
  );
};

export default WebhookCreate;
