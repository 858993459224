/* eslint-disable react/prop-types */
import React from 'react';

export const WebhookTitle = ({ record }) => (
  <span>
    Webhook
    {record ? ` > ${record.target_url}` : ''}
  </span>
);

export default WebhookTitle;
