import React from 'react';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput, ReferenceInput, required, SelectInput, email,
} from 'react-admin';
import { MerchantTitle } from './MerchantTitle';
import { SectionTitle, Separator } from '../forms/DefaultForm';
import { CardLevelInput } from '../fields/CardBrandLevel';

export const MerchantEdit = (props) => (
  <Edit title={<MerchantTitle />} {...props}>
    <SimpleForm>
      <SectionTitle label="Merchant Info" />

      <BoxedShowLayout>
        <RaBox display="flex" >
          <RaBox display="flex" flexWrap="wrap" flexGrow={4}>
            <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
              <ReferenceInput
                disabled
                label="Operating Company"
                source="operating_company.id"
                reference="operating_companies"
                validate={required()}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <TextInput source="name" />
              <TextInput label="OpCo Reference ID" source="operating_company_reference_id" />
            </RaBox>
          </RaBox>
        </RaBox>
      </BoxedShowLayout>

      <Separator />
      <SectionTitle label="PCI Compliance" />

      <BoxedShowLayout>
        <RaBox display="flex" >
          <RaBox display="flex" flexWrap="wrap" flexGrow={4}>
            <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
              <BooleanInput
                label="Enabled"
                source="pci_compliance.enabled"
                defaultValue
              />
              <CardLevelInput brand="Visa" />
              <CardLevelInput brand="Mastercard" />
              <CardLevelInput brand="Discover" />
            </RaBox>
          </RaBox>
        </RaBox>
      </BoxedShowLayout>

      <Separator />
      <SectionTitle label="PCI Contact" />

      <BoxedShowLayout>
        <RaBox display="flex" >
          <RaBox display="flex" flexWrap="wrap" flexGrow={4}>
            <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
              <TextInput label="First Name" source="pci_compliance.pci_contact.first_name" />
              <TextInput label="Last Name" source="pci_compliance.pci_contact.last_name" />
              <TextInput
                label="Email Address"
                source="pci_compliance.pci_contact.email_address"
                type="email"
                validate={email('Invalid email address format')}
              />
            </RaBox>
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </SimpleForm>
  </Edit>
);

export default MerchantEdit;
