/* eslint-disable react/prop-types */
import React from 'react';
import {
    SelectArrayInput,
    required,
} from 'react-admin';

const webhookEventChoices = [
    {id: 'merchant.created', name: 'merchant.created'},
    {id: 'merchant.updated', name: 'merchant.updated'},
    {id: 'merchant.destroyed', name: 'merchant.destroyed'},
    {id: 'merchant.pci_compliance.onboarded', name: 'merchant.pci_compliance.onboarded'},
    {id: 'merchant.pci_compliance.cancelled', name: 'merchant.pci_compliance.cancelled'},
];

export const WebhookEventSelectInput = ({source}) => {
    return (
        <SelectArrayInput source={source} choices={webhookEventChoices} translateChoice={false} validate={required()}/>
    )
};

export default WebhookEventSelectInput;
