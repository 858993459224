import React from 'react';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

import sanitizeRestProps from './sanitizeRestProps';

const hasNumberFormat = !!(
  typeof Intl === 'object' &&
  Intl &&
  typeof Intl.NumberFormat === 'function'
);

export const TotalCountField = ({
                                  className,
                                  emptyText,
                                  record,
                                  source,
                                  locales,
                                  options,
                                  textAlign,
                                  total,
                                  ...rest
                                }) => {
  const value = total ? total : '0';
  if (!value) {
    return null;
  }
  if (value == null) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : null;
  }

  return (
    <Typography
      variant="body2"
      component="span"
      className={className}
      {...sanitizeRestProps(rest)}
    >
      {hasNumberFormat ? value.toLocaleString(locales, options) : value}
    </Typography>
  );
};
TotalCountField.displayName = 'TotalCountField';
const EnhancedTotalCountField = pure(TotalCountField);

EnhancedTotalCountField.defaultProps = {
  addLabel: true,
  textAlign: 'right',
};
EnhancedTotalCountField.displayName = 'EnhancedTotalCountField';

export default EnhancedTotalCountField;