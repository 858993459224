import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { ApiKeyTitle } from './ApiKeyTitle';
import { ToggleHiddenField } from '../fields/ToggleHiddenField';

export const ApiKeyEdit = (props) => (
  <Edit title={<ApiKeyTitle />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="application_name" />
      <ToggleHiddenField source="token" />
    </SimpleForm>
  </Edit>
);

export default ApiKeyEdit;
