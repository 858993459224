import {
  required,
  minLength,
  maxLength,
  // minValue,
  // maxValue,
  // number,
  // regex,
  // email,
  // choices
} from 'react-admin';

// eslint-disable-next-line import/prefer-default-export
export const validateOpCpName = [required(), minLength(2), maxLength(255)];
