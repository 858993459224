// import {Logger} from 'aws-amplify';
//
// const logger = new Logger('HUB-Logger');

const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const authListener = (data) => {
  switch (data.payload.event) {
    case 'signIn':
      // logger.error('user signed in'); //[ERROR] My-Logger - user signed in
      sleep(100).then(() => {
        window.location.assign('/');
      });
      break;
    // case 'signUp':
    //   logger.error('user signed up');
    //   break;
    // case 'signOut':
    //   logger.error('user signed out');
    //   break;
    // case 'signIn_failure':
    //   logger.error('user sign in failed');
    //   break;
    // case 'configured':
    //   logger.error('the Auth module is configured');
    //   break;
    default:
      break;
  }
};

export default authListener;
