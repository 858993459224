import React from 'react';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  FunctionField,
  BooleanField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  EmailField,
} from 'react-admin';

import { MerchantTitle } from './MerchantTitle';
import { CardLevelField } from '../fields/CardBrandLevel';
import { SectionTitle, Separator } from '../forms/DefaultForm';

export const MerchantShow = (props) => (
  <Show title={<MerchantTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <SectionTitle label="Merchant Info" />

        <BoxedShowLayout>
          <RaBox display="flex" >
            <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
              <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
                <TextField source="id" label="Merchant ID" />
                <ReferenceField label="Operating Company" source="operating_company.id" reference="operating_companies">
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="name" />
                <TextField source="operating_company_reference_id" label="OpCo Reference ID" />
              </RaBox>
            </RaBox>
          </RaBox>
        </BoxedShowLayout>

        <Separator />
        <SectionTitle label="PCI Compliance" />

        <BoxedShowLayout>
          <RaBox display="flex" >
            <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
              <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
                <BooleanField source="pci_compliance.enabled" label="PCI Compliance Enabled" />
                <TextField source="pci_compliance.boarding_status" label="PCI Boarding Status" />
                <TextField source="pci_compliance.overall_pci_compliance_status" label="PCI Overall Status" />

                <TextField
                  label="Visa Level"
                  source="pci_compliance.visa_level"
                />
                <TextField
                  label="Mastercard Level"
                  source="pci_compliance.mastercard_level"
                />
                <TextField
                  label="Discover Level"
                  source="pci_compliance.discover_level"
                />
              </RaBox>
            </RaBox>
          </RaBox>
        </BoxedShowLayout>

        <Separator />
        <SectionTitle label="PCI Contact" />

        <BoxedShowLayout>
          <RaBox display="flex" >
            <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
              <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
                <TextField label="First Name" source="pci_compliance.pci_contact.first_name" />
                <TextField label="Last Name" source="pci_compliance.pci_contact.last_name" />
                <EmailField
                  label="Email Address"
                  source="pci_compliance.pci_contact.email_address"
                />
              </RaBox>
            </RaBox>
          </RaBox>
        </BoxedShowLayout>

      </Tab>
      <Tab label="History">
        <ReferenceManyField
            reference="versions"
            target="merchants__rxid__history"
            addLabel={false}
        >
          <Datagrid>
            <TextField source="event" sortable={false}/>
            <FunctionField source="changes" className="json" render={ record => JSON.stringify(record.changes, undefined, 2) } sortable={false}/>
            <TextField source="changed_by" sortable={false}/>
            <DateField source="changed_at" showTime sortable={false}/>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="PCI Compliance History">
        <ReferenceManyField
            reference="versions"
            target="merchants__rxid__pci_compliance__history"
            addLabel={false}
        >
          <Datagrid>
            <TextField source="event" sortable={false}/>
            <FunctionField source="changes" className="json" render={ record => JSON.stringify(record.changes, undefined, 2) } sortable={false}/>
            <TextField source="changed_by" sortable={false}/>
            <DateField source="changed_at" showTime sortable={false}/>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="PCI Contact History">
        <ReferenceManyField
            reference="versions"
            target="merchants__rxid__pci_compliance__pci_contact__history"
            addLabel={false}
        >
          <Datagrid>
            <TextField source="event" sortable={false}/>
            <FunctionField source="changes" className="json" render={ record => JSON.stringify(record.changes, undefined, 2) } sortable={false}/>
            <TextField source="changed_by" sortable={false}/>
            <DateField source="changed_at" showTime sortable={false}/>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default MerchantShow;
