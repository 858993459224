/* eslint-disable react/prop-types */
import React from 'react';
import {
  RadioButtonGroupInput,
  TextField,
} from 'react-admin';

const cardLevelChoices = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
];

export const CardLevelInput = ({ brand }) => {
  const brandLowerCase = brand.toLowerCase();
  const brandCapitalized = brandLowerCase.charAt(0).toUpperCase() + brandLowerCase.slice(1);
  return (
    <RadioButtonGroupInput
      label={`${brandCapitalized} Level`}
      source={`pci_compliance.${brandLowerCase}_level`}
      choices={cardLevelChoices}
      defaultValue="4"
    />
  );
};

export const CardLevelField = ({ brand }) => {
  const brandLowerCase = brand.toLowerCase();
  const brandCapitalized = brandLowerCase.charAt(0).toUpperCase() + brandLowerCase.slice(1);
  return (
    <TextField
      label={`${brandCapitalized} Level`}
      source={`pci_compliance.${brandLowerCase}_level`}
    />

  );
};
