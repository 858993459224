import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { ToggleHiddenField } from '../fields/ToggleHiddenField';
import { ApiKeyTitle } from './ApiKeyTitle';

export const ApiKeyShow = (props) => (
  <Show title={<ApiKeyTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="application_name" />
      <ToggleHiddenField source="token" />
    </SimpleShowLayout>
  </Show>
);

export default ApiKeyShow;
