import React from 'react';
import {
    Edit, SimpleForm, TextInput
} from 'react-admin';
import {ToggleHiddenField} from '../fields/ToggleHiddenField';
import WebhookTitle from "./WebhookTitle";
import WebhookEventSelectInput from "../fields/WebhookEventSelectInput";

export const WebhookEndpointEdit = props => (
    <Edit title={<WebhookTitle/>} {...props}>
        <SimpleForm>
            <TextInput label="Target URL" source="target_url" type="url"/>
            <WebhookEventSelectInput source='events'/>
            <ToggleHiddenField source="token"/>
        </SimpleForm>
    </Edit>
);

export default WebhookEndpointEdit;