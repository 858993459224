/* eslint-disable prefer-promise-reject-errors */

import * as R from 'ramda';
import { Auth } from 'aws-amplify';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';
import { CognitoUser } from 'amazon-cognito-identity-js';

// applyBinaryFn = (* -> a) -> [*] -> a
export const applyBinaryFn = (f) => R.apply(R.binary(f));

// getError :: Object -> a
export const getError = R.prop('error');

// signIn :: String -> String -> Object
export const signIn = applyBinaryFn(Auth.federatedSignIn.bind(Auth));

// login :: Object -> Object
export const login = (params) => {
  if (params instanceof CognitoUser) {
    return Promise.resolve(params);
  }
  const error = getError(params);
  if (error) {
    return Promise.reject(error);
  }
  return Promise.resolve(R.compose(
    signIn,
    R.props(['username', 'password']),
  )(params));
};

export const logout = () => Auth.signOut();

export const currentSession = () => Auth.currentSession().then((session) => {
  if (!session) {
    return Promise.reject('You need to sign in to access that page.');
  }
  const idToken = session.getIdToken();
  if (idToken) {
    localStorage.setItem('token', idToken.getJwtToken());
  }
  return Promise.resolve(session);
});

const AuthProvider = (type, params) => {
  if (type === AUTH_LOGIN) {
    return login(params);
  }
  if (type === AUTH_LOGOUT) {
    return logout(params);
  }
  if (type === AUTH_CHECK) {
    if (window.location.pathname === '/login_redirect') {
      return Promise.resolve({});
    }
    return currentSession();
  }
  if (type === AUTH_ERROR) {
    return Promise.resolve(); // 4xx errors should not log the user out.
  }
  if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve({}); // Unimplemented (global permissions)
  }
  return Promise.reject(`Unsupported authentication method ${type}.`);
};

export default AuthProvider;
