/* eslint-disable react/prop-types */
import React from 'react';
import Chip from '@material-ui/core/Chip'

export const TextArrayField = ({ record, source }) => {
  const array = record[source]
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div/>
  } else {
    return (
      <>
        {array.map(item => <Chip label={item} key={item}/>)}
      </>
    )
  }
};

export default TextArrayField;