/* eslint-disable camelcase */
import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';
import { parse } from 'query-string';

export const ApiKeyCreate = (props) => {
  const { operating_company_id: operating_company_string } = parse(props.location.search);

  const operating_company_id = operating_company_string;
  const redirect = operating_company_id ? `/operating_companies/${operating_company_id}/show/api_keys` : false;

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="Operating Company"
          source="operating_company.id"
          reference="operating_companies"
          disabled={!!operating_company_id}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="application_name" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default ApiKeyCreate;
