// eslint-disable-next-line import/no-extraneous-dependencies
import StorefrontIcon from '@material-ui/icons/Storefront';
import { MerchantList } from './MerchantList';
import { MerchantShow } from './MerchantShow';
import { MerchantEdit } from './MerchantEdit';
import { MerchantCreate } from './MerchantCreate';

export default {
  list: MerchantList,
  show: MerchantShow,
  edit: MerchantEdit,
  create: MerchantCreate,
  icon: StorefrontIcon,
};
