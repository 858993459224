/* eslint-disable react/prop-types */
import React from 'react';

export const OperatingCompanyTitle = ({ record }) => (
  <span>
    Operating Companies
    {record ? ` > ${record.name}` : ''}
  </span>
);

export default OperatingCompanyTitle;
