/* eslint-disable react/prop-types */
import React from 'react';

export const ApiKeyTitle = ({ record }) => (
  <span>
    API Key
    {record ? ` > ${record.application_name}` : ''}
  </span>
);

export default ApiKeyTitle;
