import React from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import get from 'lodash/get';

export const MonospacedTextField = ({source, record, text}) => {
  return (
    <Typography component="div">
      <Box fontFamily="Monospace" fontSize="h6.fontSize" m={1} >
        {text || get(record, source)}
      </Box>
    </Typography>
  );
};

export default MonospacedTextField;