import React from 'react';
import {
  List,
  TopToolbar,
  CreateButton,
  ExportButton,
  Datagrid,
  TextField,
  ReferenceManyField
} from 'react-admin';
import MonospacedTextField from "../fields/MonospacedTextField";

import { OperatingCompanyTitle } from './OperatingCompanyTitle';

import TotalCountField from "../fields/TotalCountField";
import OperatingCompaniesSyncButton from './OperatingCompaniesSyncButton';

const OperatingCompanyListActions = (props) => (
    <TopToolbar>
        <CreateButton/>
        <OperatingCompaniesSyncButton/>
        <ExportButton/>
    </TopToolbar>
);

export const OperatingCompanyList = (props) => (
  <div>
    <List title={<OperatingCompanyTitle />} actions={<OperatingCompanyListActions />} bulkActionButtons={false} {...props}>
      <Datagrid rowClick="show">
        {/* <TextField source="id" label="OpCo ID"/> */}
        <TextField source="name" />
        <MonospacedTextField source="control_scan_partner_guid" />
        <ReferenceManyField
          label="Merchants"
          reference="merchants"
          target="operating_company_reference_token_eq"
          sortable={false}
        >
          <TotalCountField/>
        </ReferenceManyField>
      </Datagrid>
    </List>
  </div>
);

export default OperatingCompanyList;
