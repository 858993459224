/* eslint-disable react/prop-types,import/no-extraneous-dependencies */
import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    float: 'right',
  },
};

const AddWebhookButton = ({ classes, record }) => (
  <Button
    variant="contained"
    className={classes.button}
    component={Link}
    label="Add a Webhook Endpoint"
    title="Add a Webhook Endpoint"
    to={{
      pathname: '/webhook_endpoints/create',
      search: `?operating_company_id=${record.id}`,
      state: { record: { operating_company: { id: record.id } } },
    }}
  >
    <AddIcon />
  </Button>
);

export default withStyles(styles)(AddWebhookButton);
