/* eslint-disable react/prop-types */
import React from 'react';

export const MerchantTitle = ({ record }) => (
  <span>
    Merchant
    {record ? ` > ${record.name}` : ''}
  </span>
);

export default MerchantTitle;
