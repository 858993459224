// eslint-disable-next-line import/no-extraneous-dependencies
import BusinessIcon from '@material-ui/icons/Business';
import { OperatingCompanyList } from './OperatingCompanyList';
import { OperatingCompanyShow } from './OperatingCompanyShow';
import { OperatingCompanyCreate } from './OperatingCompanyCreate';
import { OperatingCompanyEdit } from './OperatingCompanyEdit';


export default {
  list: OperatingCompanyList,
  show: OperatingCompanyShow,
  create: OperatingCompanyCreate,
  edit: OperatingCompanyEdit,
  icon: BusinessIcon,
};
