/* eslint-disable import/no-extraneous-dependencies,react/prop-types */
import React from 'react';
import { Typography, Box } from '@material-ui/core';

export const SectionTitle = ({ label }) => (
  <Typography variant="h6" gutterBottom>
    {label}
  </Typography>
);

export const Separator = () => <Box pt="1em" />;
