// eslint-disable-next-line max-len
/* eslint-disable react/require-default-props,react/no-unused-prop-types,import/no-extraneous-dependencies,react/prop-types */
import React from 'react';

import PropTypes from 'prop-types';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';


import { ThemeProvider } from '@material-ui/styles';

import { lightTheme } from './themes';

import LoginForm from './LoginForm';
import { login } from '../AuthProvider';

const style = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // background: 'url(https://source.unsplash.com/random/1600x900)',
    background: 'url(https://source.unsplash.com/random/1600x900/?nature,water,architecture)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
});

const LoginPage = ({ classes }) => (
  <LoginForm
    submitHandler={login}
    classes={classes}
  />
);

LoginPage.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in LoginPage won't get
// the right theme
const LoginWithTheme = (props) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <LoginPage {...props} />
  </ThemeProvider>
);

const enhance = compose(
  withStyles(style),
  withRouter,
);

export default enhance(LoginWithTheme);
