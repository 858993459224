import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { validateOpCpName } from './validations';
import { OperatingCompanyTitle } from './OperatingCompanyTitle';

export const OperatingCompanyEdit = (props) => (
  <Edit title={<OperatingCompanyTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateOpCpName} />
      <TextInput source="control_scan_partner_guid" />
    </SimpleForm>
  </Edit>
);

export default OperatingCompanyEdit;
