import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  required,
  email,
} from 'react-admin';
import { SectionTitle, Separator } from '../forms/DefaultForm';
import { CardLevelInput } from '../fields/CardBrandLevel';

export const MerchantCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <SectionTitle label="Merchant Info" />
      <ReferenceInput
        label="Operating Company"
        source="operating_company.id"
        reference="operating_companies"
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput label="OpCo Reference ID" source="operating_company_reference_id" />
      <Separator />
      <SectionTitle label="PCI Compliance" />
      <BooleanInput
        label="Enabled"
        source="pci_compliance.enabled"
        defaultValue
        options={{ disabled: true }}
      />
      <CardLevelInput brand="Visa" />
      <CardLevelInput brand="Mastercard" />
      <CardLevelInput brand="Discover" />

      <Separator />
      <SectionTitle label="PCI Contact" />
      <TextInput
        label="First Name"
        source="pci_compliance.pci_contact.first_name"
        validate={required()}
      />
      <TextInput
        label="Last Name"
        source="pci_compliance.pci_contact.last_name"
        validate={required()}
      />
      <TextInput
        label="Email Address"
        source="pci_compliance.pci_contact.email_address"
        type="email"
        validate={[email('Invalid email address format'), required()]}
      />
    </SimpleForm>
  </Create>
);

export default MerchantCreate;
