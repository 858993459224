import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
} from 'react-admin';

import { ToggleHiddenField } from '../fields/ToggleHiddenField';
import { VersionTitle } from './VersionTitle';

export const VersionShow = (props) => (
  <Show title={<VersionTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="History ID" />
      <TextField source="event" />
      <TextField source="changes" />
      <TextField source="changed_by" />
      <DateField source="changed_at" />
    </SimpleShowLayout>
  </Show>
);

export default VersionShow;
