/* eslint-disable react/prop-types */
import React from 'react';
import {
  Show,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  EditButton,
  DeleteWithConfirmButton,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  UrlField,
} from 'react-admin';

import { ToggleHiddenField } from '../fields/ToggleHiddenField';
import AddApiKeyButton from './AddApiKeyButton';
import AddWebhookButton from "./AddWebhookButton";
import { OperatingCompanyTitle } from './OperatingCompanyTitle';
import TextArrayField from "../fields/TextArrayField";
import MonospacedTextField from "../fields/MonospacedTextField";

export const OperatingCompanyShow = (props) => (
  <Show title={<OperatingCompanyTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <MonospacedTextField source="control_scan_partner_guid" />
      </Tab>
      <Tab label="API Keys" path="api_keys">
        <AddApiKeyButton />
        <ReferenceManyField
          reference="api_keys"
          target="operating_company_reference_token_eq"
          addLabel={false}
        >
          <Datagrid>
            <TextField source="application_name" />
            <ToggleHiddenField source="token" />
            <EditButton />
            {/* eslint-disable-next-line react/destructuring-assignment */}
            <DeleteWithConfirmButton confirmTitle="Delete API Key?" redirect={props.location.pathname} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Webhooks" path="webhooks">
        <AddWebhookButton />
        <ReferenceManyField
          reference="webhook_endpoints"
          target="operating_company_reference_token_eq"
          addLabel={false}
        >
          <Datagrid>
            <UrlField source="target_url" />
            <TextArrayField source="events"/>
            <ToggleHiddenField source="token" />
            <EditButton />
            {/* eslint-disable-next-line react/destructuring-assignment */}
            <DeleteWithConfirmButton confirmTitle="Delete Webhook?" redirect={props.location.pathname} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="history">
        <ReferenceManyField
            reference="versions"
            target="operating_companies__rxid__history"
            addLabel={false}
        >
          <Datagrid>
            <TextField source="event" sortable={false}/>
            <FunctionField source="changes" className="json" render={ record => JSON.stringify(record.changes, undefined, 2) } sortable={false}/>
            <TextField source="changed_by" sortable={false}/>
            <DateField source="changed_at" showTime sortable={false}/>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
export default OperatingCompanyShow;
