/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Route } from 'react-router-dom';
import LoginRedirect from './LoginRedirect';

export default [
  <Route exact path="/login_redirect" component={LoginRedirect} noLayout />,
  // <Route exact path="/bar" component={Bar} />,
  // <Route exact path="/baz" component={Baz} noLayout />,
];
