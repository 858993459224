/* eslint-disable react/prop-types,import/no-extraneous-dependencies */
import React, {useState} from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useNotify, fetchStart, fetchEnd, Button, useRefresh } from 'react-admin';


const styles = {
  button: {
    marginTop: '1em',
    float: 'left',
  },
};

const OperatingCompaniesSyncButton = ({ classes }) => {
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const sync = () => {
        setLoading(true);
        dispatch(fetchStart());
        var bt = 'Bearer ' + localStorage.getItem('token');
        fetch(process.env.REACT_APP_API_PATH + '/operating_companies/sync', { method: 'GET', headers: { Authorization: bt }})
            .then((response) => {
                if(!response.ok) {
                    notify('Sync Error', 'warning');
                }
                else {
                    notify('Operating Companies Synchronized');
                    refresh();
                }
            })
            .catch((e) => {
                notify(`Sync Error: ${e.message}`, 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd());
            });
    };
    return <Button
        variant="text"
        label="Sync"
        onClick={sync}
        disabled={loading}>
        <SyncIcon />
    </Button>;
};

export default withStyles(styles)(OperatingCompaniesSyncButton);


